import React from 'react';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';
import MediaKitColors from '../../components/media-kit-colors';
import MediaKitAssets from '../../components/media-kit-assets';

const MediaKit = () => {
  const colors = [
    {
      RGB: '(3, 184, 255)',
      CMYK: '(99, 28, 0, 0)',
      HEX: '#03b8ff',
    },
    {
      RGB: '(50, 53, 55)',
      CMYK: '(2, 1, 0, 78)',
      HEX: '#050038',
    },
  ];

  return (
    <Layout>
      <PageHero
        title="Media Kit"
        subtitle="Here are presented guidelines on how to use Hexowatch logo and colors without having to negotiate legal agreements"
      />
      <Section about>
        <Tabs />
        <MediaKitAssets />
        <MediaKitColors colors={colors} />
      </Section>
    </Layout>
  );
};

export default MediaKit;
