import React, { FC, useState } from 'react';
import { ColorBoxesProps } from '../../types';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Button from '../button';
import FileSaver from 'file-saver';
import assetsPDF from '../../pdf/Hexowatch-logo.pdf';
import logo from '../../images/hexowatch-logo.svg';

const MediaKitColorBoxes: FC<ColorBoxesProps> = ({ colors }) => {
  const [copiedColor, setCopiedColor] = useState('');

  const handleCopyToClipboard = (containerid: string) => {
    const hiddenField = document.getElementById(
      containerid + 'input'
    ) as HTMLInputElement;
    hiddenField.select();
    document.execCommand('copy');
    setCopiedColor(containerid);
    setTimeout(() => {
      setCopiedColor('');
    }, 1000);
  };

  const handleDownloadPdf = () => {
    FileSaver.saveAs(assetsPDF, 'Hexowatch-Logo.pdf');
  };

  return (
    <div className="row-colors">
      <div className="col-12 col-lg-6 left-box text-center justify-content-center">
        <div className="logo">
          <img src={logo} alt="Hexowatch.com" width={210} height={50} className="mb-4" />
        </div>
        <Button primary onClick={handleDownloadPdf}>
          Download Hexowatch Logo
        </Button>
      </div>
      <div className="col-12 col-lg-6 text-center right-box">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="f_p f_size_30 l_height30 f_600 t_color3 mb_20">
              Hexowatch Colors
            </h2>
            <p>
              The two colors presented here are the primary colors of Hexowatch.
            </p>
          </div>
          {colors.map(color => {
            const entries = Object.entries(color);
            return (
              <div key={color.HEX}>
                <div
                  id={color.HEX}
                  onClick={() => handleCopyToClipboard(color.HEX)}
                  className="col-12 col-sm-6 text-center color-box"
                  style={{ background: `${color.HEX}` }}
                >
                  <div>
                    <input
                      type="text"
                      id={color.HEX + 'input'}
                      defaultValue={color.HEX}
                    />
                  </div>
                  <FontAwesomeIcon icon={faCopy} className="copy-icon" />
                </div>
                {entries.map(item => {
                  return (
                    <div key={item[1]} className="col-12">
                      {' '}
                      {item[0]}: {item[1]}{' '}
                    </div>
                  );
                })}
                {copiedColor === color.HEX && (
                  <div
                    id={color.HEX}
                    className="alert alert-success copied-message"
                  >
                    Color <strong>{color.HEX}</strong> is copied to clipboard
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MediaKitColorBoxes;
